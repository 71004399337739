import {
  PrivacyStatus,
  Type,
} from '@wix/ambassador-social-groups-v2-group/types';

export const RestrictionLabels = {
  [Type.EVENTS]: 'groups-web.restriction.events.label',
  [Type.ADMIN_APPROVAL]: 'groups-web.restriction.admin.label',
  [Type.PAID_PLANS]: 'groups-web.restriction.plans.label',
  [Type.UNKNOWN]: 'groups-web.restriction.unknown.label',
};

export const PrivacyLabels = {
  [PrivacyStatus.PUBLIC]: {
    title: 'groups-web.types.public.name',
    subtitle: 'groups-web.types.public.description',
  },
  [PrivacyStatus.PRIVATE]: {
    title: 'groups-web.types.private.name',
    subtitle: 'groups-web.types.private.description',
  },
  [PrivacyStatus.SECRET]: {
    title: 'groups-web.types.private.name',
    subtitle: 'groups-web.visibility.hidden.description',
  },
  [PrivacyStatus.UNKNOWN]: {
    title: 'UNKNOWN',
    subtitle: 'UNKNOWN',
  },
};

export const VisibilityLabels = {
  [PrivacyStatus.PUBLIC]: {
    title: 'groups-web.visibility.visible',
    subtitle: 'groups-web.visibility.visible.label',
  },
  [PrivacyStatus.PRIVATE]: {
    title: 'groups-web.visibility.visible',
    subtitle: 'groups-web.visibility.visible.label',
  },
  [PrivacyStatus.SECRET]: {
    title: 'groups-web.visibility.hidden',
    subtitle: 'groups-web.visibility.hidden.label',
  },
  [PrivacyStatus.UNKNOWN]: {
    title: 'UNKNOWN',
    subtitle: 'UNKNOWN',
  },
};
