import React from 'react';
import cls from 'classnames';
import { Box } from 'wui/Box';

import classes from './Layout.scss';

interface IProps extends React.ComponentProps<typeof Box> {}

export function Column(props: IProps) {
  const { className, ...rest } = props;

  return (
    <Box
      direction="vertical"
      className={cls(classes.column, className)}
      {...rest}
    />
  );
}

Column.displayName = 'Column';
