import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  selectCanSeeGroupActivity,
  selectGroupActivity,
  selectGroupStatus,
  selectMemberLabel,
} from 'store/selectors';

import { settings as settingsParams } from 'settings/group';

import {
  Comment as CommentIcon,
  Group as GroupIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Hide } from 'wui/Hide';
import { Card } from 'wui/Card';
import { Skeleton } from 'wui/Skeleton';
import { Divider } from 'wui/Divider';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemText } from 'wui/ListItemText';

import { GROUP_ACTIVITY_INFO_CARD } from '../dataHooks';

import classes from './GroupActivityCard.scss';

interface IProps extends React.ComponentProps<typeof Card> {
  groupId: string;
}

export function GroupActivityCard(props: IProps) {
  const { groupId, ...rest } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();

  const canSeeGroupActivity = useSelector(selectCanSeeGroupActivity(groupId));
  const { label: membersLabel } = useSelector(selectMemberLabel(groupId));
  const status = useSelector(selectGroupStatus(groupId));
  const activity = useSelector(selectGroupActivity(groupId));

  const isLoading = status.activity.loading;

  if (!canSeeGroupActivity) {
    return null;
  }

  const title =
    t('groups-web.activity') + ': ' + t('groups-web.lastNDays', { N: 30 });

  const newPostsLabel = t('groups-web.activity.posts_icu', {
    count: activity?.totalPosts ?? 0,
  });

  const newMembersLabel = t('groups-web.activity.members', {
    membersLabel: t(membersLabel, {
      count: activity?.newMembers,
    }),
  });

  return (
    <Wire
      cssVarName="showActivity--flex"
      legacyFallback={settings.get(settingsParams.showActivity)}
    >
      <Card className={classes.root} {...rest}>
        <CardHeader wired title={title} />
        <Hide if={isMobile}>
          <Divider inset />
        </Hide>
        <Show if={isMobile}>
          <CardContent>
            <List disablePadding>
              <ListItem disableGutters data-hook={GROUP_ACTIVITY_INFO_CARD}>
                <ListItemIcon>
                  <CommentIcon />
                </ListItemIcon>
                <ListItemText
                  title={
                    isLoading ? (
                      <Skeleton width="50px" />
                    ) : (
                      `${activity?.totalPosts || 0}`
                    )
                  }
                  subtitle={newPostsLabel}
                  titleProps={{ className: classes.counter }}
                  subtitleProps={{ className: classes.label, secondary: false }}
                />
              </ListItem>
              <ListItem disableGutters data-hook={GROUP_ACTIVITY_INFO_CARD}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText
                  title={
                    isLoading ? (
                      <Skeleton width="50px" />
                    ) : (
                      `${activity?.newMembers || 0}`
                    )
                  }
                  subtitle={newMembersLabel}
                  titleProps={{ className: classes.counter }}
                  subtitleProps={{ className: classes.label, secondary: false }}
                />
              </ListItem>
            </List>
          </CardContent>
        </Show>
        <Hide if={isMobile}>
          <CardContent>
            <Box padding="SP9 0">
              <Box
                width="100%"
                direction="vertical"
                align="center"
                data-hook={GROUP_ACTIVITY_INFO_CARD}
              >
                <Typography variant="p2-16" className={classes.label}>
                  {newPostsLabel}
                </Typography>
                <Typography variant="p2-16" className={classes.counter}>
                  {isLoading ? (
                    <Skeleton width="50px" />
                  ) : (
                    activity?.totalPosts || 0
                  )}
                </Typography>
              </Box>

              <Divider orientation="vertical" />

              <Box
                width="100%"
                direction="vertical"
                align="center"
                data-hook={GROUP_ACTIVITY_INFO_CARD}
              >
                <Typography variant="p2-16" className={classes.label}>
                  {newMembersLabel}
                </Typography>
                <Typography variant="p2-16" className={classes.counter}>
                  {isLoading ? (
                    <Skeleton width="50px" />
                  ) : (
                    activity?.newMembers || 0
                  )}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Hide>
      </Card>
    </Wire>
  );
}

GroupActivityCard.displayName = 'GroupActivityCard';
