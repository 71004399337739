import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { selectGroupIdBySlug } from 'store/selectors';

import { Column, Layout } from 'Group/Widget/Layout';

import { GroupInfoCard } from './GroupInfo';
import { GroupRulesCard } from './GroupRules';
import { GroupActivityCard } from './GroupActivity';
import { GroupDescriptionCard } from './GroupDescription';

import { ABOUT_PAGE_ROOT } from './dataHooks';

export function AboutPage() {
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));

  if (isMobile) {
    return (
      <Layout data-hook={ABOUT_PAGE_ROOT}>
        <Column>
          <GroupInfoCard groupId={groupId} sideBorders={false} />
          <GroupDescriptionCard groupId={groupId} sideBorders={false} />
          <GroupActivityCard groupId={groupId} sideBorders={false} />
          <GroupRulesCard groupId={groupId} sideBorders={false} />
        </Column>
      </Layout>
    );
  }

  return (
    <Layout data-hook={ABOUT_PAGE_ROOT}>
      <Column>
        <GroupActivityCard groupId={groupId} />
        <GroupDescriptionCard groupId={groupId} />
        <GroupRulesCard groupId={groupId} />
      </Column>
      <Column>
        <GroupInfoCard groupId={groupId} />
      </Column>
    </Layout>
  );
}

AboutPage.displayName = 'AboutPage';
