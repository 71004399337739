import React from 'react';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import {
  Lock as LockIcon,
  Public as PublicIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import type { IGroup } from 'store/groups/types';

interface IProps extends React.SVGAttributes<SVGElement> {
  group: IGroup;

  size?: string | number;
}

export function GroupPrivacyIcon(props: IProps) {
  const { group, ...rest } = props;

  switch (group.privacyStatus) {
    case PrivacyStatus.PUBLIC:
      return <PublicIcon {...rest} />;

    case PrivacyStatus.PRIVATE:
    case PrivacyStatus.SECRET:
      return <LockIcon {...rest} />;

    case PrivacyStatus.UNKNOWN:
    default:
      return null;
  }
}

GroupPrivacyIcon.displayName = 'GroupPrivacyIcon';
