import React from 'react';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import { Visible as VisibleIcon } from '@wix/wix-ui-icons-common/on-stage';
import SecretIcon from 'assets/icons/Secret.svg';

import type { IGroup } from 'store/groups/types';

interface IProps extends React.SVGAttributes<SVGElement> {
  group: IGroup;

  size?: string | number;
}

export function GroupVisibilityIcon(props: IProps) {
  const { group, ...rest } = props;

  switch (group.privacyStatus) {
    case PrivacyStatus.PUBLIC:
    case PrivacyStatus.PRIVATE:
      return <VisibleIcon {...rest} />;

    case PrivacyStatus.SECRET:
      return <SecretIcon {...rest} />;

    case PrivacyStatus.UNKNOWN:
    default:
      return null;
  }
}

GroupVisibilityIcon.displayName = 'GroupVisibilityIcon';
