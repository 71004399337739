import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { RichContent } from '@wix/ricos';
import { EditorEventsProvider } from '@wix/ricos';
import { extractContent } from 'common/utils/ricos';
import { groupsAboutEditClicked } from '@wix/bi-logger-groups/v2';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';
import { groupsAboutEditSave } from '@wix/bi-logger-groups-data/v2';

import { selectHasAdminRole, selectIsGroupUpdating } from 'store/selectors';

import { Card } from 'wui/Card';
import { Fade } from 'wui/Fade';
import { CardHeader } from 'wui/CardHeader';
import { Hide } from 'wui/Hide';
import { Show } from 'wui/Show';
import { TextButton } from 'wui/TextButton';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { GroupDescriptionViewer } from './GroupDescriptionViewer';
import { GroupDescriptionEditor } from './GroupDescriptionEditor';

interface IProps extends React.ComponentProps<typeof Card> {
  groupId: string;
}

export function GroupDescriptionCard(props: IProps) {
  const { groupId, ...rest } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { group$ } = useController();
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const [isEditing, setIsEditing] = useState(params.openEditor);

  useDidUpdate(() => {
    if (!isUpdating) {
      setIsEditing(false);
    }
  }, [isUpdating]);

  return (
    <EditorEventsProvider>
      <Card {...rest}>
        <CardHeader
          wired
          title={t('groups-web.about')}
          action={
            isAdmin && (
              <TextButton wired onClick={openEditor}>
                {t('groups-web.viewer.about.edit')}
              </TextButton>
            )
          }
        />

        <Show if={isMobile}>
          <GroupDescriptionViewer groupId={groupId} />

          <GroupDescriptionEditor
            isOpen={isEditing}
            groupId={groupId}
            onClose={closeEditor}
            onSubmit={handleSubmit}
          />
        </Show>

        <Hide if={isMobile}>
          <SwitchTransition>
            <Fade key={isEditing ? 'editor' : 'viewer'}>
              {isEditing ? (
                <GroupDescriptionEditor
                  groupId={groupId}
                  onClose={closeEditor}
                  onSubmit={handleSubmit}
                />
              ) : (
                <GroupDescriptionViewer groupId={groupId} />
              )}
            </Fade>
          </SwitchTransition>
        </Hide>
      </Card>
    </EditorEventsProvider>
  );

  function closeEditor() {
    setIsEditing(false);
  }

  function openEditor() {
    setIsEditing(true);

    bi.report(
      groupsAboutEditClicked({
        groupId,
        origin: 'edit_descr_link',
      }),
    );
  }

  function handleSubmit(description: RichContent | undefined) {
    group$.updateGroupInfo(groupId, { description });

    bi.report(
      groupsAboutEditSave({
        groupId,
        length: extractContent(description).text?.length,
        origin: params.origin || 'about_section',
      }),
    );
  }
}

GroupDescriptionCard.displayName = 'GroupDescriptionCard';
