import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { RichContent, RicosEditorType } from '@wix/ricos';
import { EditorEventsContext } from '@wix/ricos';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';
import { useRicosFormat } from 'common/utils/ricos';
import { RichContentEditor } from 'common/components/RichContent/loadable';
import { RceEditorFallback } from 'common/components/RichContent/RceFallback';

import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Button } from 'wui/Button';
import { ButtonGroup } from 'wui/ButtonGroup';
import { InputDialog } from 'wui/InputDialog';
import { DialogMobileHeader } from 'wui/DialogMobileHeader';
import { DialogContent } from 'wui/DialogContent';
import { TextButton } from 'wui/TextButton';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

interface IProps {
  isOpen?: boolean;
  groupId: string;

  onSubmit(description: RichContent | undefined): void;
  onClose(): void;
}

export function GroupDescriptionEditor(props: IProps) {
  const { groupId, isOpen, onClose, onSubmit } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const editor = React.useContext(EditorEventsContext);

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const [isBusy, setIsBusy] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const $ricos = useRef<RicosEditorType>(null);

  const description = useRicosFormat(group.description);

  const isLoading = isBusy || isUpdating;

  if (!isMobile) {
    return (
      <>
        <CardContent disableSideGutters>
          <RichContentEditor
            wired
            ref={$ricos}
            usage="About"
            postId={groupId}
            containerId={GROUPS_APP_DEFINITION_ID}
            contentId="about_group"
            content={description}
            groupId={groupId}
            onChange={validate}
            onBusyChange={handleEditorBusyChange}
            fallback={<RceEditorFallback wired />}
            loadingPlaceholder={<RceEditorFallback wired />}
            autofocus="end"
          />
        </CardContent>
        <CardActions align="right">
          <ButtonGroup>
            <Button outlined onClick={onClose}>
              {t('groups-web.cancel')}
            </Button>
            <Button
              loading={isLoading}
              onClick={handleSubmit}
              disabled={isLoading || !isValid}
            >
              {t('groups-web.save')}
            </Button>
          </ButtonGroup>
        </CardActions>
      </>
    );
  }

  return (
    <InputDialog
      isOpen={isOpen}
      hideCloseButton
      focusTrap={false}
      onClose={onClose}
    >
      <DialogMobileHeader>
        <TextButton onClick={onClose} variant="secondary">
          {t('groups-web.cancel')}
        </TextButton>
        <TextButton
          loading={isLoading}
          onClick={handleSubmit}
          disabled={!isValid || isLoading}
        >
          {t('groups-web.save')}
        </TextButton>
      </DialogMobileHeader>

      <DialogContent scrollable={false} disableSideGutters>
        <RichContentEditor
          autoFocus
          ref={$ricos}
          usage="About"
          postId={groupId}
          containerId={GROUPS_APP_DEFINITION_ID}
          contentId="about_group"
          content={description}
          groupId={groupId}
          onChange={validate}
          onBusyChange={handleEditorBusyChange}
          fallback={<RceEditorFallback bw />}
          loadingPlaceholder={<RceEditorFallback bw />}
        />
      </DialogContent>
    </InputDialog>
  );

  function validate() {
    const helpers = $ricos.current?.getContentTraits();

    setIsValid(!helpers?.isEmpty);
  }

  function handleEditorBusyChange(isBusy: boolean) {
    setIsBusy(isBusy);
  }

  async function handleSubmit() {
    setIsBusy(true);
    const description = await editor.publish();

    onSubmit(description);
    setIsBusy(false);
  }
}

GroupDescriptionEditor.displayName = 'GroupDescriptionEditor';
