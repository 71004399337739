import React from 'react';
import { useSelector } from 'react-redux';

import { selectGroup } from 'store/selectors';
import { useRicosFormat } from 'common/utils/ricos';
import { RichContentViewer } from 'common/components/RichContent/Viewer';

import { CardContent } from 'wui/CardContent';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

interface IProps {
  groupId: string;
}

export function GroupDescriptionViewer(props: IProps) {
  const { groupId } = props;

  const group = useSelector(selectGroup(groupId));

  const description = useRicosFormat(group.description);

  return (
    <CardContent>
      <RichContentViewer
        groupId={groupId}
        postId={groupId}
        containerId={GROUPS_APP_DEFINITION_ID}
        usage="About"
        contentId="about_group"
        content={description}
      />
    </CardContent>
  );
}

GroupDescriptionViewer.displayName = 'GroupDescriptionViewer';
