import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { selectGroupRules } from 'store/selectors';

import { Card } from 'wui/Card';
import { Box } from 'wui/Box';
import { Hide } from 'wui/Hide';
import { Divider } from 'wui/Divider';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { Stack } from 'wui/Stack';

import classes from './GroupRulesCard.scss';

interface IProps extends React.ComponentProps<typeof Card> {
  groupId: string;
}

export function GroupRulesCard(props: IProps) {
  const { groupId, className, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const rules = useSelector(selectGroupRules(groupId));

  if (!rules?.length) {
    return null;
  }

  return (
    <Card className={cls(classes.root, className)} {...rest}>
      <CardHeader wired title={t('groups-web.about.rules.title')} />
      <Hide if={isMobile}>
        <Divider inset />
      </Hide>
      <CardContent>
        <Stack gap="SP5" direction="vertical">
          {rules.map((rule, index) => (
            <Box direction="vertical" gap="SP0" key={index}>
              <Typography as="h3" className={classes.title}>
                {rule.title}
              </Typography>
              <Typography className={classes.description}>
                {rule.description}
              </Typography>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

GroupRulesCard.displayName = 'GroupRulesCard';
